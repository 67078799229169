<template>
  <button
    :class="{
      'disabled': disabled,
      'active': active,

      'small': size === 'small',
      'medium': size === 'medium',

      'secondary': type === 'secondary',
      'warning': type === 'warning',
      'success': type === 'success',
      'danger': type === 'danger'
    }"
    :disabled="disabled"
    class="ra-button"
    v-on="$listeners"
  >
    <span v-if="!vote">
      {{ text }}
    </span>
    <span v-else>
      <span v-if="voteType === 'up'">
        <img :src="upvote">
      </span>
      <span v-else>
        <img :src="downvote">
      </span>
    </span>
  </button>
</template>

<script>
import upvote from '@/assets/vote/upvote.png'
import downvote from '@/assets/vote/downvote.png'

export default {
  name: 'RaButton',

  props: {
    text: {
      type: String,
      default: () => 'Click'
    },

    type: {
      type: String,
      default: () => 'primary'
    },

    size: {
      type: String,
      default: () => 'big'
    },

    disabled: {
      type: Boolean
    },

    active: {
      type: Boolean
    },

    vote: {
      type: Boolean
    },

    voteType: {
      type: String,
      default: () => 'up'
    }
  },

  computed: {
    upvote() {
      return upvote
    },

    downvote() {
      return downvote
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-button {
    outline: none;
    width: 100%;
    max-width: 450px;
    text-align: center;
    display: inline-block;
    border: none;
    font: 500 16px/1 "Poppins", sans-serif;
    padding: 20px;
    margin: 6px 0;
    cursor: pointer;
    border-radius: $borderRadius;
    background: $primaryColor;
    color: $backgroundColor;
    position: relative;
    top: 0;
    transition: 0.2s ease;
    &:hover,
    &.hover {
      top: -3px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    }
    &:active,
    &.active {
      background: $primaryYellowShade4;
      outline: none;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
      top: 0;
    }
    &.disabled {
      opacity: 0.4;
      user-select: none;
      pointer-events: none;
    }
    &.medium {
      padding: 15px 18px;
      width: auto;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    &.small {
      padding: 10px 12px;
      width: auto;
      font-size: 14px;
      font-weight: 500;
    }
    &.secondary {
      background: $secondaryColor;
      color: white;
      &:active,
      &:focus,
      &.active {
        background: $secondaryBlueShade4;
        outline: none;
      }
    }
    &.warning {
      background: $warningColor;
      &:active,
      &:focus,
      &.active {
        background: $warningYellowShade4;
      }
    }
    &.success {
      background: $successColor;
      &:active,
      &:focus,
      &.active {
        background: $successGreenShade4;
      }
    }
    &.danger {
      background: $dangerColor;
      &:active,
      &:focus,
      &.active {
        background: $dangerRedShade4;
      }
    }
  }
</style>
