<template>
  <h4
    :class="[
      'ra-quote',
      {'ra-quote-italic': italic},
      {'ra-quote-more': more}
    ]"
  >
    <slot />
  </h4>
</template>

<script>
export default {
  name: 'RaQuote',

  props: {
    italic: {
      type: Boolean
    },

    more: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/scss/variables.scss';

  .ra-quote {
    font-weight: 300;
    border-left: 6px solid $primaryColor;
    padding-left: 8px;
    background: linear-gradient(45deg, $quoteShadow 40%, transparent 60%);

    &-italic {
      font-style: italic;
    }

    &-more {
      background: linear-gradient(45deg, $quoteShadow 60%, transparent 75%);
    }
  }
</style>
