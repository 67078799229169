<template>
  <div class="ra-basic ra-basic-text">
    <RaQuote
      italic
      more
    >
      Loosing power of our words its like loosing ourselfs
    </RaQuote>
    <img
      src="/book-image/book-cover.png"
      class="ra-book"
    >
    <br />
    <br />
    <template v-if="false">
      <RaButton
        text="Buy on Amazon"
        disabled
      />
      - <b>coming soon (English, physical)</b>
      <br />
      <RaButton
        text="Buy Ebook"
        disabled
      />
      - <b>also (English, Ebook)</b>
    </template>
    <template v-else>
      Coming to public: <b>TBA</b>
      <br />
      <br />
    </template>
    <p>
      The book is written to share personal and team development experience over the past 11 years, thoughts, results of experiments with development methodologies and tools for achieving business goals in product.
    </p>
    <h3>What's in the book</h3>
    <ul>
      <li>
        <b>Management</b>
        - issues what is affects business, end user, things that decreasing developing productivity and some solutions, research and direct talk with end users
      </li>
      <li>
        <b>Development</b>
        - soft/hard, devs techniques, awareness of success, product view, business understanding etc.
      </li>
      <li>
        <b>Team</b>
        - leading product, peoples, growing as part of team, product and business connections, tips to keep balance in team work performance
      </li>
      <li>
        <b>Consciousness</b>
        - down-earth, responsibility, self-awareness, inner righteousness,
        respect, critical thinking, inner peace, networking,
        soft skills etc
      </li>
      <li>
        <b>
          Hire/Hunting
        </b>
        - how interview works for each side including business, growing,
        what is important for you, and what is important for the company, points of interest etc
      </li>
      <li>
        <b>Growth</b>
        - emotional intelligence, networking, soft/hard skills
      </li>
      <li>
        <b>Personal brand</b> - skills, recognition, immediacy, techniques, mentoring, networking etc.
      </li>
    </ul>
    <br />
    <p>
      <b>Reason to write:</b> I wanted to summarize every note I had in all this years,
      everything that can be useful for any soul in development industry
      <br />
      <br />
      The book will break down some problems, will give some solutions,
      paths and directions, and I hope its will be helpful enough for some busineses, teams and peoples.
      And if it is, im happy to push industry to better
    </p>
  </div>
</template>

<script>
import RaButton from '@/components/elements/RaButton.vue'
import RaQuote from '@/components/elements/RaQuote.vue'

export default {
  name: 'Book',

  metaInfo: {
    title: 'Book',
    titleTemplate: '%s | Roman Almazov'
  },

  components: {
    RaButton,
    RaQuote
  }
}
</script>

<style lang="scss" scoped>
  .ra-book {
    width: 100%;
    max-width: 376px;
  }
</style>
