var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"ra-button",class:{
    'disabled': _vm.disabled,
    'active': _vm.active,

    'small': _vm.size === 'small',
    'medium': _vm.size === 'medium',

    'secondary': _vm.type === 'secondary',
    'warning': _vm.type === 'warning',
    'success': _vm.type === 'success',
    'danger': _vm.type === 'danger'
  },attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(!_vm.vote)?_c('span',[_vm._v(" "+_vm._s(_vm.text)+" ")]):_c('span',[(_vm.voteType === 'up')?_c('span',[_c('img',{attrs:{"src":_vm.upvote}})]):_c('span',[_c('img',{attrs:{"src":_vm.downvote}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }